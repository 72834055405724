import React from 'react';
import ContentLoader from 'react-content-loader';

import styled from '@emotion/styled';

const TableSkeleton = () => {
  return (
    <StyledSkeletonTable>
      {Array(5)
        .fill(undefined)
        .map(() => (
          Array(5)
            .fill(undefined).map((_, idx) => (
              <div key={idx}>
                <ContentLoader speed={2} height="56" width="100%">
                  <rect
                    x="0"
                    y="21"
                    width={idx ? "124" : "248"}
                    height="12"
                    rx="3"
                  />
                </ContentLoader>
              </div>
            ))
        ))}
    </StyledSkeletonTable>
  );
};

export default TableSkeleton;

const StyledSkeletonTable = styled.div`
  margin: 24px 0;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
`;
