import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useForm, Controller } from 'react-hook-form';

import { Button, Dropdown, Input } from '@xchange/uikit';
import { InputPlaces } from 'components';
import MaskInputController from 'components/MaskInputController';
import getAddressComponents from 'utils/getAddressComponents';
import statesOptions from 'utils/statesOptions';
import { REGEXP } from 'consts';
import Context from '../VendorsContext';
interface VendorFormProps {
  isEditing: boolean;
  defaultValues?: any;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  onDelete: () => void;
}

const VendorForm: React.FC<VendorFormProps> = ({
  isEditing,
  defaultValues = {},
  onSubmit,
  onCancel,
  onDelete
}) => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues
  });
  const { vendorTypeSelectOptions } = useContext(Context);

  const handleAddressChange = (place: google.maps.places.PlaceResult) => {
    const addressComponents = getAddressComponents(place);

    if (!addressComponents) return;

    setValue('address1', addressComponents.address1);
    setValue('city', addressComponents.city);
    setValue('county', addressComponents.county);
    setValue('state', addressComponents.state);
    setValue('zip', addressComponents.zip);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} className="vendor-form">
      <div className="input-grid">
        <div className="input-grid__row">
          <Controller
            control={control}
            rules={{ required: "Required" }}
            name="category"
            render={({ field }) => (
              <Dropdown
                options={vendorTypeSelectOptions}
                label="Vendor Category*"
                placeholder="Select category"
                error={errors.category?.message}
                {...field}
              />
            )}
          />
          <Input
            {...register('name', {
              required: "Required"
            })}
            label="Contact Name*"
            placeholder="Enter contact name"
            error={errors.name?.message}
          />
        </div>

        <div className="input-grid__row">
          <Input
            {...register('email', {
              required: "Required",
              pattern: {
                value: REGEXP.EMAIL,
                message: 'Invalid email address'
              }
            })}
            name="email"
            error={errors.email?.message}
            label="Email*"
            placeholder="Enter email"
          />
          <Input
            {...register('company_name', {
              required: "Required"
            })}
            label="Company Name*"
            placeholder="Enter company name"
            error={errors.company_name?.message}
          />
        </div>

        <div className="input-grid__row">
          <MaskInputController
            control={control}
            name="phone"
            defaultValue=""
            rules={{
              validate: value => !value?.includes('_') || 'Invalid phone format'
            }}
            mask="+1 (999) 999-9999"
            error={errors.phone?.message}
            label="Phone"
            placeholder="(000) 000-0000"
          />
          <MaskInputController
            control={control}
            name="fax"
            defaultValue=""
            rules={{
              validate: value => !value?.includes('_') || 'Invalid fax format'
            }}
            mask="+1 (999) 999-9999"
            error={errors.fax?.message}
            label="Fax"
            placeholder="(000) 000-0000"
          />
        </div>

        <div className="input-grid__row">
          <Controller
            control={control}
            name="address"
            defaultValue=""
            rules={{
              required: 'Required',
              maxLength: {
                value: 100,
                message: `Address can not exceed 100 characters`
              },
              pattern: {
                value: REGEXP.ADDRESS,
                message: 'Invalid Address'
              }
            }}
            render={({ field: { onChange, ...field } }) => (
              <InputPlaces
                label="Enter Vendor Address*"
                placeholder="Enter Address"
                error={errors.address?.message}
                autoComplete="off"
                onChange={onChange}
                onPlaceChange={(query, place) => {
                  handleAddressChange(place);
                  onChange(query);
                }}
                {...field}
              />
            )}
          />
        </div>

        <div className="input-grid__row">
          <Input
            {...register('address1', { required: 'Required' })}
            label="Address Line 1*"
            placeholder="Enter address 1"
            error={errors.address1?.message}
          />
          <Input
            {...register('address2')}
            label="Address Line 2"
            placeholder="Enter address 2"
          />
        </div>

        <div className="input-grid__row">
          <Input
            {...register('city', { required: 'Required' })}
            label="City*"
            placeholder="Enter City"
            error={errors.city?.message}
          />
          <Controller
            control={control}
            rules={{
              required: "Required"
            }}
            name="state"
            defaultValue=""
            render={({ field }) => (
              <Dropdown
                options={statesOptions}
                label="State*"
                placeholder="Select State"
                error={errors.state?.message}
                {...field}
              />
            )}
          />
        </div>

        <div className="input-grid__row">
          <Input
            {...register('zip', {
              required: "Required",
              pattern: {
                value: REGEXP.ZIP_CODE,
                message: 'Invalid postal code'
              }
            })}
            error={errors.zip?.message}
            label="Zip Code*"
            placeholder="Enter Zip"
          />
        </div>

        <div className="input-grid__buttons">
          {isEditing && (
            <Button
              onClick={e => {
                e.preventDefault();
                onDelete();
              }}
              className="delete-btn"
            >
              Delete
            </Button>
          )}
          <Button secondary onClick={onCancel}>Cancel</Button>
          <Button>
            {isEditing ? `Save` : `Add`}
          </Button>
        </div>
      </div>
    </StyledForm>
  );
};

export default VendorForm;

const StyledForm = styled.form`
  .input-grid {
    display: grid;
    grid-gap: 16px;

    &__row {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      column-gap: 16px;
    }

    &__buttons {
      margin-top: 70px;
      display: flex;
    }

    .secondary {
      margin-left: auto;
    }

    .button + .button {
      margin-left: 16px;
    }

    .delete-btn + .button {
      margin-left: auto;
    }

    .input, .dropdown {
      width: 100%;
      margin: 0;
    }


    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      .input-grid__row {
        grid-auto-flow: row;
        row-gap: 16px;
      }
    }
  }
`;
