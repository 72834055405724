import React, { useContext, useMemo } from 'react';

import styled from '@emotion/styled';
import { Button, Dropdown, Icon, Input } from '@xchange/uikit';
import useLayout from 'hooks/useLayout';
import Context from '../VendorsContext';
interface VendorsControlsProps {
  onAddNewVendorClick: () => void;
}

const VendorsControls: React.FC<VendorsControlsProps> = (
  { onAddNewVendorClick }) => {
  const {
    search,
    setSearch,
    vendorTypeFilter,
    setVendorTypeFilter,
    vendorTypeSelectOptions
  } = useContext(Context);
  const layout = useLayout();

  const vendorTypeDropdownText = useMemo(() => {
    if (!vendorTypeFilter.length || vendorTypeFilter.length === vendorTypeSelectOptions.length) return 'All';
    if (vendorTypeFilter.length === 1) {
      return (vendorTypeSelectOptions?.find(item => item.value === vendorTypeFilter[0])?.text ||
        vendorTypeFilter[0]) as string;
    }
    return `${vendorTypeFilter.length} types`;
  }, [vendorTypeFilter, vendorTypeSelectOptions]);

  return (
    <StyledVendorsControls className="vendors-controls">
      <h3>Vendors</h3>
      <Input
        value={search}
        onChange={e => setSearch(e.target.value)}
        icon="search"
        iconPosition="left"
        placeholder="Search"
        className="vendors-controls__input"
        data-cy="input_search"
        blurOnSubmit
      />
      <StyledDropdown
        className="vendors-controls__dropdown"
        value={vendorTypeFilter}
        onChange={setVendorTypeFilter}
        options={vendorTypeSelectOptions}
        label="Type:"
        text={vendorTypeDropdownText}
        buttonLike
        multiple
        optionAll
      />
      <Button
        className="vendors-controls__btn"
        onClick={onAddNewVendorClick}
      >
        {layout === 'mobile' ? (
          <Icon name="add-user" />
        ) : `Add new vendor`}
      </Button>
    </StyledVendorsControls>
  );
};

export default React.memo(VendorsControls);

const StyledVendorsControls = styled.div`
  display: grid;
  grid-template-areas: 'area-header area-header area-header' 'area-input area-select area-button';
  grid-template-columns: 3fr auto auto;
  margin-bottom: 32px;

  .vendors-controls {
    h3 {
      grid-area: area-header;
    }
    &__input {
      grid-area: area-input;
      margin: 0px auto 0px 0px;
      width: 300px;
    }
    &__btn {
      grid-area: area-button;
      margin: 0 0 0 20px;
      padding: 0 7px;
      display: flex;
      align-items: center;
    }
    &__dropdown {
      grid-area: area-select;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-areas: 'area-header area-select area-button' 'area-input area-input area-input';

    .vendors-controls {
      h3 {
        margin-right: auto;
      }
      &__input {
        width: 100%;
        margin-top: 16px;
      }
      &__dropdown {
        margin-left: auto;
      }
      &__btn {
        width: 32px;
      }
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  .dropdown-options {
    width: 154px;
    right: 0;
  }
`;
