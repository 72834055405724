import React from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import { Button, Icon } from '@xchange/uikit';
import { getListingAddress } from 'utils/listing';
interface VendorDetailsProps {
  data: Vendor;
  onClickEdit: (value: Vendor) => void;
}

const VendorDetails: React.FC<VendorDetailsProps> = ({ data, onClickEdit }) => {
  const { email, phone, fax, listings = [], address1, address2, city, state, zip } = data;

  const vendorAddress = getListingAddress(data);

  return (
    <StyledVendorDetails className="vendor-details">
      <div className="vendor-contacts">
        <div className="vendor-details__header">
          Contact Info
        </div>

        {vendorAddress && (
          <div className="vendor-details__info">
            <Icon name='house' />
            {vendorAddress}
          </div>
        )}
        {email && (
          <a href={`mailto:${email}`} className="vendor-details__info vendor-details__link">
            <Icon name="email" />
            {email}
          </a>
        )}
        {phone && (
          <a href={`tel:${phone}`} className="vendor-details__info vendor-details__link">
            <Icon name="phone" />
            {phone}
          </a>
        )}
        {fax && (
          <a href={`tel:${fax}`} className="vendor-details__info vendor-details__link">
            <Icon name="phone" />
            {fax}
          </a>
        )}
      </div>

      <div className="vendor-projects">
        <div className="vendor-details__header">
          Recent Projects
        </div>
        <div className="vendor-details__header">
          Type
        </div>
        <div className="vendor-details__header">
          Status
        </div>
        {listings.map((listing: VendorProject) => (
          <React.Fragment key={listing.id}>
            <div className="vendor-details__info" key={listing.id}>
              {listing.google_address}
            </div>
            <div className="vendor-details__info">{listing.type}</div>
            <div
              className={clsx('vendor-details__info', {
                [listing.status.toLocaleLowerCase()]: true
              })}>
              {listing.status}
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="edit-btn">
        <Button secondary onClick={onClickEdit} >
          Edit
        </Button>
      </div>
    </StyledVendorDetails>
  );
};

export default React.memo(VendorDetails);

const StyledVendorDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  &:hover {
    background: ${props => props.theme.colors.seashell};
  }

  .vendor-details {
    &__header {
      margin-bottom: 6px;
      color: ${props => props.theme.colors.grayDark};
    }
    &__info {
      display: flex;
      align-items: center;
      margin: 6px 0;
      color: ${props => props.theme.colors.mineShaft};
      .icon {
        margin-right: 12px;
      }
      &.active {
        color: ${props => props.theme.colors.lightGreen};
      }
    }
    &__link {
      color: inherit;
      text-decoration: none;
    }
  }

  .vendor-contacts {
    flex: 1;
    margin: 0 18px;
  }

  .vendor-projects {
    flex: 2;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
  }

  .edit-btn {
    flex-basis: 100%;
    margin-right: 112px;
    text-align: right;
    .button {
      width: 75px;
    }
  }
`;
