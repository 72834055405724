import React, { useState } from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import Icon from 'components/Icon';
import VendorDetails from './VendorDetails';

interface VendorsListItemProps {
  data: Vendor;
  onClickEdit: (value: Vendor) => void;
}

const VendorsListItem: React.FC<VendorsListItemProps> = ({
  data,
  onClickEdit
}) => {
  const [openVendorDetails, setOpenVendorDetails] = useState<boolean>(false);
  const { name, category, company_name, transactions_open, transactions_closed } = data;

  return (
    <>
      <StyledVendorsListItem
        className={clsx('vendors-list__item', { 'vendors-list__item__open': openVendorDetails })}
        onClick={() => setOpenVendorDetails(v => !v)}>
        <div className="vendors-list__item-info vendors-list__item-name">
          <Icon name="plus-mini" fill="#000000" />
          {name}
        </div>
        <div className="vendors-list__item-info vendors-list__item-category">{category}</div>
        <div className="vendors-list__item-info vendors-list__item-company">{company_name}</div>
        <div className="vendors-list__item-info vendors-list__item-active">
          {transactions_open || '-'}
        </div>
        <div className="vendors-list__item-info vendors-list__item-closed">
          {transactions_closed || '-'}
        </div>
      </StyledVendorsListItem>
      {openVendorDetails && <VendorDetails data={data} onClickEdit={onClickEdit} />}
    </>
  );
};

export default React.memo(VendorsListItem);

const StyledVendorsListItem = styled.div`
  display: flex;
  .icon {
    flex-shrink: 0;
    transition: all 300ms;
  }
  &.vendors-list__item__open {
    .icon-plus-mini {
      transform: rotate(45deg);
      fill: ${props => props.theme.colors.red};
    }
  }
  .icon-plus-mini {
    margin-right: 12px;
  }
`;
