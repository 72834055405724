import React from 'react';
import { Router } from '@reach/router';
import VendorsPage from 'views/VendorsPage';
import PrivateRoute from 'components/PrivateRoute';

const Vendors = () => {
  return (
    <Router>
      <PrivateRoute path="/vendors" component={VendorsPage} />
    </Router>
  );
};

export default Vendors;
