import React, { useContext, useState } from 'react';

import styled from '@emotion/styled';

import useLayout from 'hooks/useLayout';
import MainLayout from 'components/MainLayout';
import { SideMenu } from 'components';
import ConfirmationDialog from 'components/ConfirmationDialog';
import VendorsControls from './components/VendorsControls';
import VendorsList from './components/VendorsList';
import VendorForm from './components/VendorForm';
import TableSkeleton from './components/TableSkeleton';
import Context, { ContextProvider } from './VendorsContext';

const VendorsPage = () => {
  const { vendors,
    isLoading,
    editVendor,
    deleteVendor
  } = useContext(Context);
  const [editVendorData, setEditVendorData] = useState<Vendor | null>(null);
  const [openDeleteVendor, setOpenDeleteVendor] = useState<boolean>(false);
  const layout = useLayout();

  const closeVendorForm = () => setEditVendorData(null);

  const closeDeleteVendor = () => setOpenDeleteVendor(false);

  const handleEditVendor = async (values: Vendor) => {
    await editVendor(values);
    closeVendorForm();
  };

  const handleDeleteVendor = async (vendorId: number) => {
    await deleteVendor(vendorId);
    setOpenDeleteVendor(false);
    closeVendorForm();
  };

  return (
    <StyledMainLayout>
      <StyledVendorsPage className="vendors-page">
        <div className="sticky-header">
          <VendorsControls
            onAddNewVendorClick={() => setEditVendorData({} as Vendor)}
          />
          {layout !== 'mobile' && (
            <div className="list-header">
              <div className="list-header__item name">Name</div>
              <div className="list-header__item">Type</div>
              <div className="list-header__item">Company Name</div>
              <div className="list-header__item">Active Transactions</div>
              <div className="list-header__item">Closed Transactions</div>
            </div>
          )}
        </div>

        {isLoading ? <TableSkeleton /> : <VendorsList vendors={vendors} onClickEdit={setEditVendorData} />}
      </StyledVendorsPage>
      <StyledSideMenu
        open={Boolean(editVendorData)}
        title={editVendorData?.id ? `Edit vendor` : `Add new vendor`}
        onClose={closeVendorForm}
      >
        <VendorForm
          defaultValues={editVendorData}
          isEditing={Boolean(editVendorData?.id)}
          onSubmit={handleEditVendor}
          onCancel={closeVendorForm}
          onDelete={() => setOpenDeleteVendor(true)}
        />
      </StyledSideMenu>
      <ConfirmationDialog
        modalTitle="Delete Vendor"
        open={openDeleteVendor}
        onSubmit={() => handleDeleteVendor(editVendorData?.id!)}
        onClose={closeDeleteVendor}>
        <p>Are you sure you want to remove this vendor? This action can’t be undone.</p>
      </ConfirmationDialog>
    </StyledMainLayout>
  );
};

export default props => (
  <ContextProvider>
    <VendorsPage {...props} />
  </ContextProvider>
);

const StyledVendorsPage = styled.div`
  position: relative;

  h3 {
    margin: 0 0 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-right: auto;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding-top: 32px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-wrap: wrap;
  }

  .sticky-header {
    width: calc(100vw - 10px);
    background-color: #fff;
    z-index: 1000;
  }

  .list-header {
    display: flex;
    align-items: center;
    width: 100%;
    color: ${props => props.theme.colors.grayDark};
    &__item {
      padding: 12px 3px;
      font-size: 14px;
      line-height: 16px;
      flex: 1;
      &.name {
        flex: 2;
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    .sticky-header {
      width: 100%;
      position: sticky;
      top: -1px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .sticky-header {
      position: fixed;
      margin-top: -1px;
      width: calc(100vw);
      padding: 32px 20px 0 0;
    }
    .list-header {
      width: 675px;
    }
  }
`;

const StyledMainLayout = styled(MainLayout)`
  padding-bottom: 0;
  height: calc(100vh - 80px);
  .content {
    padding-top: 0;
  }
`;

const StyledSideMenu = styled(SideMenu)`
  .side-menu {
    min-width: 768px;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      min-width: auto;
      width: 100%;
    }
  }
`;
