import React, { useState, useEffect, useMemo } from 'react';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import {
  getVendors,
  createVendor,
  updateVendor,
  deleteVendor as deleteVendorRequest
} from 'api/vendors';
import { DropdownOption } from '@xchange/uikit';
interface ContextValues {
  vendors: Vendor[],
  editVendor: (value: Partial<Vendor>) => void;
  deleteVendor: (value: number) => void;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  vendorTypeFilter: string[];
  setVendorTypeFilter: React.Dispatch<React.SetStateAction<string[]>>;
  isLoading: boolean;
  vendorTypeSelectOptions: DropdownOption[];
}

const vendorTypeSelectOptions: DropdownOption[] = [
  { value: 'Attorney', text: 'Attorney' },
  { value: 'Lender', text: 'Lender' }
];

const Context = React.createContext({} as ContextValues);

export const ContextProvider = ({ children }) => {
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [search, setSearch] = useState<string>('');
  const [vendorTypeFilter, setVendorTypeFilter] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const filteredVendors = useMemo(() => {
    let result: Vendor[] = [...vendors];
    const lowerCaseSearchString = search.trim().toLowerCase();

    if (lowerCaseSearchString && lowerCaseSearchString.length > 1) {
      result = result.filter(
        ({ name, company_name, category }) =>
          [name, company_name, category].join('').toLocaleLowerCase().includes(lowerCaseSearchString)
      );
    }

    if (vendorTypeFilter.length && vendorTypeFilter.length !== vendorTypeSelectOptions.length) {
      result = result.filter(({ category }) => vendorTypeFilter.join('').includes(category));
    }

    return result;
  }, [vendors, search, vendorTypeFilter]);

  const fetchVendors = async () => {
    setIsLoading(true);
    try {
      const vendors = await getVendors();
      setVendors(vendors);
    } catch (err) {
      const errorMessage = tryGetFirstError(err);
      notify(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const editVendor = async (values: Vendor) => {
    try {
      const isEditing = Boolean(values?.id);
      const vendorRequest = isEditing ? updateVendor : createVendor;
      await vendorRequest(values);
      notify(`Vendor ${isEditing ? `updated` : `created`} successfully`);
      fetchVendors();
    } catch (err) {
      const errorMessage = tryGetFirstError(err);
      notify(errorMessage);
    }
  };

  const deleteVendor = async (vendorId: number) => {
    try {
      await deleteVendorRequest(vendorId);
      fetchVendors();
      notify('Vendor deleted successfully');
    } catch (err) {
      const errorMessage = tryGetFirstError(err);
      notify(errorMessage);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const values: ContextValues = {
    vendors: filteredVendors,
    editVendor,
    deleteVendor,
    search,
    setSearch,
    vendorTypeFilter,
    setVendorTypeFilter,
    isLoading,
    vendorTypeSelectOptions
  };

  return <Context.Provider value={values}>{children}</Context.Provider>;
};

export default Context;
