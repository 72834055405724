export default (place?: google.maps.places.PlaceResult) => {
  if (!place) return;

  const { address_components, geometry } = place;

  const streetNumber =
    address_components?.find(item => item.types.includes('street_number'))?.long_name || '';
  const route = address_components?.find(item => item.types.includes('route'))?.long_name || '';
  const city = address_components?.find(item => item.types.includes('locality'))?.long_name || '';
  const state =
    address_components?.find(item => item.types.includes('administrative_area_level_1'))
      ?.short_name || '';
  const zip =
    address_components?.find(item => item.types.includes('postal_code'))?.short_name || '';
  const county =
    address_components?.find(item => item.types.includes('administrative_area_level_2'))
      ?.short_name || '';
  const country =
    address_components?.find(item => item.types.includes('country'))?.short_name || '';
  const address1 = streetNumber ? `${streetNumber} ${route}` : route;
  const location = JSON.parse(JSON.stringify(geometry?.location));

  return {
    address1,
    city,
    state,
    zip,
    county,
    country,
    location
  };
};
