import React from 'react';

import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import VendorsListItem from './VendorsListItem';

const VendorsList = ({ vendors, onClickEdit }) => {
  const layout = useLayout();

  return (
    <StyledVendorsList className="vendors-list">
      {layout === 'mobile' && (
        <div className="list-header">
          <div className="list-header__item name">Name</div>
          <div className="list-header__item">Type</div>
          <div className="list-header__item">Company Name</div>
          <div className="list-header__item">Active Transactions</div>
          <div className="list-header__item">Closed Transactions</div>
        </div>
      )}
      {vendors.length > 0 ? (
        vendors.map((vendor: Vendor) => (
          <VendorsListItem
            data={vendor}
            key={vendor.id}
            onClickEdit={() => onClickEdit(vendor)}
          />
        ))
      ) : (
          <div className="empty-result">No result</div>
        )}
    </StyledVendorsList>
  );
};

export default React.memo(VendorsList);

const StyledVendorsList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;
  position: relative;

  .vendors-list {
    &__item {
      display: flex;
      flex-flow: wrap;
      padding: 16px 3px;
      border-top: 1px solid ${props => props.theme.colors.seashell};

      & > * {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &-info {
        flex: 1;
      }

      &:hover {
        background: ${props => props.theme.colors.seashell};
        cursor: pointer;
      }
    }
    &__item-name {
      flex: 2;
    }
  }

  .empty-result {
    margin-top: 16px;
    font-size: 16px;
    line-height: 32px;
    color: ${props => props.theme.colors.grayDark};
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 675px;
    padding: 154px 0 0 10px;
    .list-header {
      position: sticky;
      top: 154px;
      background: #fff;
      z-index: 999;
    }
    .vendors-list__item:first-of-type {
      border-top: none;
    }
  }
`;
